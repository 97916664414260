import React, { useState, useEffect, FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import BurgerIcon from './Burger';
import classNames from 'classnames';

const Topbar: FunctionComponent = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (hash: string) => {
    navigate(`/#${hash}`);
    window.scrollTo(0, 0);
  };

  const toggleMenu = () => {
    setIsMenuVisible((prev) => !prev);
  };

  useEffect(() => {
    document.body.style.overflow = isMenuVisible ? 'hidden' : 'auto';
  }, [isMenuVisible]);

  return (
    <header className="self-stretch flex flex-row items-center justify-center py-6 mq450:my-0 mq1200:py-4 px-20 mq1200:px-8 mq450:p-4 box-border max-w-full">
      <div className="flex-1 flex flex-row items-center justify-between gap-[20px] mq450:gap-0 max-w-full mq450:m-0">
        <img
          className="h-9 w-[209.8px] relative overflow-hidden shrink-0 object-contain cursor-pointer"
          loading="lazy"
          alt=""
          src="/pays-solution--logo-poziom-1@2x.png"
          onClick={() => navigate('/')}
        />
        <nav className="text-[16px] font-medium m-0 w-auto flex flex-row items-center justify-start gap-[48px] max-w-full text-left text-gray-200 font-palanquin mq800:gap-[24px] mq1125:hidden">
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[63px] whitespace-nowrap mq1200:hidden">
          <a href="#about-us" className="relative no-underline inline-block whitespace-nowrap text-black visited:text-black">About Us</a>
          </div>
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[64px] mq1200:hidden">
          <a href="#solutions" className="relative no-underline inline-block whitespace-nowrap text-black visited:text-black">Solutions</a>
          </div>
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[67px] mq1200:hidden">
          <a href="#roadmap" className="relative no-underline inline-block whitespace-nowrap text-black visited:text-black">Roadmap</a>
          </div>
          <div className="relative no-underline hidden whitespace-nowrap text-black visited:text-black">
            Partnerships
          </div>
          <div className="relative tracking-[-0.01em] font-medium inline-block min-w-[75px] whitespace-nowrap mq1200:hidden">
          <a href="#token-sale" className="relative no-underline inline-block whitespace-nowrap text-black visited:text-black">Token Sale</a>
          </div>
          <div className="relative tracking-[-0.01em] font-medium min-w-[54px] hidden mq1200:hidden">
            Contact
          </div>
        </nav>
        <div className="flex flex-row items-start justify-start gap-[8px] mq450:hidden">
          <button onClick={() => window.location.href='/Pays Solution - White Paper5.pdf'} className="cursor-pointer py-2 px-[19px] bg-[transparent] rounded-29xl flex flex-row items-center justify-center border-[1px] border-solid border-whitesmoke-200 hover:bg-lightgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-lightgray-100">
            <div className="relative text-base tracking-[-0.01em] font-medium font-palanquin text-gray-200 text-left inline-block min-w-[82px]">
              Whitepaper
            </div>
          </button>
          <button onClick={() => window.location.href='https://share-eu1.hsforms.com/1T3uP9uTCSK2S_z_EcE4Vqw2dwiun'} className="cursor-pointer [border:none] py-[9.5px] px-5 bg-[transparent] rounded-29xl [background:linear-gradient(180deg,_#2e79ee,_#30a7f9)] flex flex-row items-center justify-center whitespace-nowrap">
            <div className="relative text-base tracking-[-0.01em] font-medium font-palanquin text-white text-left inline-block min-w-[79px]">
              Join waitlist
            </div>
          </button>
        </div>
        <div className="hidden mq450:block z-50">
          <BurgerIcon toggleMenu={toggleMenu} isMenuVisible={isMenuVisible} />
        </div>
      </div>

      <div
        className={classNames(
          'fixed top-0 bottom-0 z-40 right-0 items-start justify-center bg-white transition-all mq450:flex hidden shadow-2xl shadow-slate-700 font-medium',
          {
            'left-[200%]': !isMenuVisible,
            'left-1/2 mq450:left-1/3': isMenuVisible,
          }
        )}
      >
        <div className="flex flex-col items-center justify-center gap-4 pt-28">
          <a href="#about-us" onClick={toggleMenu} className="text-[24px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
            About Us
          </a>
          <a href="#solutions" onClick={toggleMenu} className="text-[24px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
            Solutions
          </a>
          <a href="#roadmap" onClick={toggleMenu} className="text-[24px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
            Roadmap
          </a>
          <a href="#partnerships" onClick={toggleMenu} className="text-[24px] relative no-underline hidden whitespace-nowrap text-black visited:text-black">
            Partnerships
          </a>
          <a href="#token-sale" onClick={toggleMenu} className="text-[24px] relative no-underline inline-block whitespace-nowrap text-black visited:text-black">
            Token Sale
          </a>
          <a href="#contact" onClick={toggleMenu} className="text-[24px] relative no-underline hidden whitespace-nowrap text-black visited:text-black">
            Contact
          </a>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
