import { FunctionComponent } from "react";

const TCComponent: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col gap-6 justify-center py-16 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border max-w-full text-left text-45xl text-font-color-dark-800 font-palanquin mq450:box-border mq800:gap-[24px] mq800:py-[83px] mq800:px-10 mq800:box-border"> 
    <div className="flex w-full justify-center text-[20px] font-medium">TERMS AND CONDITIONS OF SERVICE</div>
    <div id="§1" className="flex flex-col gap-4">
    <div className="flex w-full justify-center text-[20px] font-medium">§1 GENERAL PROVISIONS</div>
    <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
        1. These Regulations set forth the rules for the provision of services by electronic means by
[...] (hereinafter:
“Platform”) managing the “[...]” platform, as well as the rules for the use
of the platform by Users. In the remainder of the Regulations, the term Platform written
with a capital letter refers to the above-mentioned legal entity that manages the Platform
understood as an ICT system that enables the provision of services.
</div>
<div>
2. The platform's ICT system includes a set of cooperating IT devices and software,
providing for the processing and storage, as well as sending and receiving data via
telecommunications networks, by means of a terminal device appropriate for the type of
network.
</div>
<div>
3. The Platform collects personal data, the processing of which is carried out in accordance
with the relevant regulations. Detailed provisions in this regard can be found in the
Privacy Policy.
</div>
<div>
4. The Platform provides the following services in its operations:
1) Enabling the acquisition of Tokens
2) providing the service of a non-custodial wallet technology solution.
</div>
<div>
5. The Platform does not provide the service of exchanging units of virtual currencies for
units of fiat currencies (or virtual currencies) or vice versa or units of fiat currencies for
other units of fiat currencies, nor does it store or manage or administer the Customer's
units of virtual currencies. There may be advertisements or information anywhere on the
Platform about the possibility of such services being provided by Third Party Providers,
but the Platform is not responsible for the acts and omissions of such Third Party
Providers, nor is it a party to the agreements that the User has entered into with such
Third Party Providers.
</div>
<div>
6. The services of exchange of crypto-assets into fiat currencies are provided to Users by:
our partners.
</div>
<div>
7. The Platform also does not provide payment or banking services, which in particular
should be understood as maintaining a payment or bank account, storing and enabling
deposits and withdrawals of the Customer's fiat funds. The Platform may establish
cooperation with operators of payment or banking services.
</div>
<div>
8. In order to use the services referred to in sections 5-7 independently , the User may also
establish cooperation with entities that provide such services, for whose acts and
omissions the Platform is not responsible, to the extent that this does not violate these
Regulations and to the extent that the User is not limited by the Platform's cooperation
with Third Party Providers. Accordingly , the User may not expect the Platform to provide
any services other than those specified in section 4 above, in particular, this applies to the
storage and exchange of cryptocurrency funds, as well as the acquisition of further
payment or banking services. The provision of services to the User by Third Party
Providers shall take place under the terms of the agreements between the User and such
Third Party Provider, including the consents and regulations accepted by the User.
</div>
<div>
9. The Platform also does not provide the service of providing information about the
amount of funds held or purchased by the User in fiat or crypto currencies. The Platform
is also not a market maker nor does it play on the rates of crypto-assets.
</div>
<div>
10. The Platform shall only provide services to entities that have accepted these Regulations
and entered into an agreement for the provision of services under the terms described in
these Regulations. The services are provided, for the benefit of natural persons who are
18 years of age or older, provided that they have full legal capacity , as well as legal
persons and other entities with legal capacity.
</div>
<div>
11. In these Regulations, a User shall be defined as any person who has accessed the website
or downloaded the Platform application, but has not entered into an agreement with the
Platform for the provision of services, while a Customer shall be understood as a User
who has entered into an agreement for the provision of services. Whenever a User is
referred to in the Regulations, this also applies to the Customer, particularly with regard
to rights and obligations and liability . Whenever the Regulations refer to a Customer, it
does not apply to a User who does not have the status of a Customer.
</div>
<div>
12. Services to Customers are provided for a fee in accordance with the rules set forth in the
Table of Fees. The principles of payment of fees by Third Party Providers are specified in
separate agreements concluded with these entities.
</div>
</div>
    </div>
    <div id="§2" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§2 TECHNICAL REQUIREMENTS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The Platform can only be used if you have a device with Internet access that supports a web
      browser (e.g., Chrome), as well as the ability to use JavaScript and Cookies. The use of an
      outdated browser may prevent or impede the use of the Platform. In the case of using the
      Platform via a mobile application, the User is required to have an Android or iOS mobile
      device. The Platform does not guarantee the ability to install and use the Platform via a
      mobile application if the User does not have the appropriate system updates or plug-ins.
    </div>
    <div>
      2. Each User shall, on his/her own, be responsible for ensuring that he/she has the necessary
      equipment and ICT system to properly use the Platform.
    </div>
    <div>
      3. The Platform may, from time to time, make technical changes to the platform, which will
      result in the User having to adapt to them. Such changes shall not be read as changes to the
      Regulations, and the Platform shall not be liable for the inability of an existing User to adapt
      to the new technical requirements. The User will be obliged to comply with the new technical
      requirements necessary for the continued use of the Platform as soon as they are implemented
      by the Platform.
    </div>
    <div>
      4. The Platform is not responsible for the acts and omissions of third parties whose services are
      used by the User in conjunction with the use of the Platform's services, and does not
      guarantee that any use of such third parties will be possible in conjunction with the use of the
      Platform.
    </div>
    <div>
      5. Customers from certain countries, even if they are not Excluded States, may temporarily or
      permanently encounter only some or all services due to regulations or technical issues.
    </div>
    <div>
      6. If the User has created an account on the Platform in the web version or in the application, the
      Platform does not guarantee that the User will be able to use the Platform in the application or
      web version, respectively.
    </div>
  </div>
    </div>
    <div id="§3" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§3 SERVICE PROVISION AND RISK</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    1. The User's status as a Client can be referred to with the conclusion of the contract with
the Platform, which occurs upon acceptance of the Regulations, other requirements,
statements and consents, and completion of the registration process. Each Customer,
throughout the period of having an active account, thus confirms that he/she agrees to
the provisions of the Regulations in the current version.
    </div>
    <div>
    2. Each Customer, throughout the period of provision of services to him/her by the
Platform, declares that:
1) he/she is aware of the risks associated with the use of cryptocurrencies, which risks
may result, in particular, in the partial or total loss of funds stored using the Platform,
bearing in mind in particular:
a) legal risks related to the possibility of changes in generally applicable laws,
guidelines or the issuance of an act of public authority , which may result in the
blocking or confiscation by the competent authorities or bodies of funds held in
cryptocurrencies or funds exchanged for fiat and the imposition of administrative
or criminal sanctions,
3. 4. 5. 6. 7. 8. 9. b) c) the risk of hacking attacks and other technical risks that may result, in particular,
in a breach of the Wallet's security and theft of funds,
volatility risk, which may even result in a total loss of value in the market of the
funds held,
2) is aware of the general risks associated with the use of the Internet, such as hacking
attacks, risks of fraud and scams,
- for which the Platform cannot be held responsible.
    </div>
    <div>
      3. The contract is concluded for an indefinite period of time.
    </div>
    <div>
      4. In order to register on the Platform, the User shall provide: email adress..
    </div>
    <div>
      5. Each User shall be entitled to create and use one account on the Platform. Each User shall
be solely responsible for the use and management of his/her account and shall be solely
liable in case of loss of access to the account or data in case of sharing login data with
others or improper storage of account access data. You are responsible for protecting your
data, passwords, account elements or confidential information and sharing them with
third parties at your own risk.
    </div>
    <div>
      6. In the event that the Platform determines that a User has more than one account on the
Platform, such User shall be requested to delete all accounts except one within 7 days. If
the accounts are not removed, the Platform will stop providing services to that User and
block access to the account. In the event of subsequent deletion of accounts, the Platform
is entitled to unblock the account of such User. Access to an account may also be blocked
if it is determined that the account has been accessed by a person other than the User to
whom the account belongs.
    </div>
    <div>
      7. A User uses the Platform by accessing the Platform's website and logging into his or her
account or by logging into the Platform's dedicated application. Attempting to use the
Platform through other applications or websites, as well as unsecured networks, may not
allow proper use of the Platform and may result in loss of data or funds by the User. The
User is also obliged to protect his/her payment or bank account data and not to make it
available on the Platform.
    </div>
    <div>
      8. The Platform does not guarantee the timeliness or truthfulness of offers to buy or sell
crypto-assets that are available from Third Party Providers. All terms and conditions of
cooperation or individual cryptocurrency exchange transactions or terms and conditions
of execution of individual payment services are governed exclusively by the regulations
or agreements binding the User with such Third Party Providers.
    </div>
    <div>
      9. The Platform generates individual addresses of virtual currencies for Users.
    </div>
    <div>
      10. The Customer may fund his/her Wallet using the methods listed on the site, all transfers
go through Third Party Providers. Funding of the account is done through selected
payment operators.
    </div>
    <div>
      11. The Platform does not provide the possibility of storing funds in fiat, guaranteeing only
the technical device itself for storing crypto assets. Such a possibility may also not be
provided by Third Party Providers, providing exchange of crypto for fiat.
    </div>
    <div>
      12. Agreements with individual Third Party Providers may stipulate procedures governing
how to deal with unsuccessful transfer of fiat funds by providing incorrect data or
technical difficulties in making a fiat transfer, or in case of analogous problems related to
fiat-crypto exchange, the Platform shall not be liable here. Agreements with individual
Third Party Providers also specify the rules for making fiat transfers, including
withdrawals to the User's bank account. Individual Third Party Providers may only
provide the User with a fiat-crypto exchange service or a fiat money storage service.
Thus, the User may need to establish cooperation with several providers who will
provide different services, depending on the purpose adopted by the User.
    </div>
    <div>
      13. What Cryptocurrency can be accepted?
    </div>
    <div>
      14. Cryptocurrencies that can be transferred to the Client's Wallet are not tradable financial
instruments, including securities or payment instruments. The Platform only provides
services for the storage of crypto assets that are payment tokens that are not e-money , or
any other regulated instrument within the meaning of payment services regulations, or
that are utility tokens. The Platform does not provide any services in the field of financial
instruments, in particular, it does not operate a trading system for such instruments, it is
not a brokerage house or an investment company .
    </div>
    <div>
      15. The Platform also does not support or allow the user to use the Platform for other
activities and actions (including supplementary protocols) than those expressly stated in
these Regulations. In case of doubt, such activities should be considered prohibited and
may become grounds for blocking the User's account.
    </div>
    <div>
      16. The Platform shall not be responsible for the terms and conditions of transactions carried
out by Third Party Providers, including those with whom it has established a
partnership, in particular: the amount or method of charging transaction or other fees,
the requirements for carrying out KYC or the documents requested from the User, the
timing of transactions, the maximum or minimum limits of transactions or funds that can
be held in the account, the non-acceptance of certain fiat currencies or crypto assets,
technical requirements, legal or other restrictions.
    </div>
    <div>
      17. The Platform may be required to conduct a KYC process against the User and obtain
certain data, documents or information about the User. Failure to obtain such data
documents or information may result in the inability to enter into a service agreement
and create an account on the platform or termination of the agreement and blocking
access to the account.
    </div>
    <div>
      18. Whenever the Regulations refer to the prevention of access to the account or the cessation
      of services, it means in particular the prevention of use of the Wallet.
    </div>
  </div>
    </div>
    <div id="§4" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§ 4 PROHIBITED ACTIVITIES</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. By accepting these Regulations, the User, including the Client, agrees to:
1) abide by the Regulations, in particular with respect for the Platform, other Users or
Third Party Providers,
2) use the Platform without violating generally applicable laws, rules of social
coexistence or good morals,
3) not use the Platform for hacking activities or transmission of dangerous data.
    </div>
    <div>
      2. The Platform may block access to a User's account on the Platform or terminate the
agreement and delete such account in cases specified in these Regulations and when:
1) there is a judgment, even if not final, or an admiralty decision or other act of state
authority is issued, stating that the User conducts illegal activities with the use of the
account on the Platform, or the funds that the User accumulates on the Wallet originate
from a crime or are stored illegally ,
2) in the event that the Platform independently determines or is informed of the unlawful
nature of the User's activities or funds.
    </div>
    <div>
      3. The Customer shall be informed of the blocking or deletion of the account immediately
      by communicating using the Platform or by email.
    </div>
    <div>
      4. In the event of a judgment, even if not final, an administrative decision or the issuance of
any other act of state authority regardless of the jurisdiction or area of such authority ,
which obligates the Platform to transfer such funds to this or any other designated
authority , the Platform will transfer the funds immediately , no later than the date
indicated in the act of the authority , if such a date is indicated. The funds held by the
User may also be blocked in the case specified in section 2.
    </div>
    <div>
      5. The Platform may also temporarily block the User's access to the account or accumulated
funds for a period of no more than 48h in order to investigate the compatibility of the
User's activities and the possibility of the situation referred to in section 2 and section 4.
    </div>
    <div>
      6. The User hereby consents to the assignment of the contract concluded with the Platform
or to the subcontracting of the provision of services to a third party by the Platform,
without the need to obtain further consent from the User. The Regulations of the
provision of services, in the event of a change in the entity , may be changed under the
same Regulations as the change in the provision of services by the Platform.
    </div>
    <div>
      7. Temporary blocking of access to an account also means temporary suspension of the
provision of services. Suspension of the provision of services by the Platform due to the
circumstances described in the Regulations shall not be construed as a failure to perform
or improper performance of services by the Platform.
    </div>
  </div>
    </div>
    <div id="§5" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§ 5 METHODS AND CONDITIONS OF TERMINATION OF SERVICE AGREEMENT</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The Customer may terminate the agreement concluded by creating an account and
      accepting the Regulations without notice at any time.
    </div>
    <div>
      2. The Platform may terminate the contract for the provision of services in situations
specified in these Regulations, being the basis for deletion of the account or in the case of
violation of generally applicable laws, without notice. The basis for termination may also
be the violation of the personal interests of the Platform, members of the Platform's
bodies and its employees and collaborators, as well as other Users, even if through the
use of threats against these people, the use of terms commonly used as insults against
them or the use of discriminatory terms, especially on the basis of race, sexual
orientation, religion, political or union affiliation.
    </div>
    <div>
      3. The Agreement may also be terminated if the Client is a resident of an Excluded Country
or has become such a resident during the term of the Agreement, or if the Client has
failed to provide the documents requested by the Platform within the prescribed period,
including if the need to provide the documents arose later, including due to a change in
generally applicable laws or the issuance of a judgment or other act of governmental
authority .
    </div>
    <div>
      4. The Platform may also terminate the Agreement without cause with 3 days' notice,
during which period the Platform will continue to provide services. During the
termination period, the Platform will not be obliged at the request of the Customer to
begin providing services, the requirement of which may exceed the termination period.
    </div>
    <div>
      5. Upon termination of the Agreement, the Client will be required to transfer all funds
accumulated in the Wallet immediately . If the Client does not transfer the funds within 7
days of termination, the funds may be lost or treated by the Platform as an interest-free
deposit, unless the Client has been deprived of the funds due to the occurrence of a
situation described in the Regulations. This paragraph regarding the transfer of funds
shall also apply in any case of termination by the Platform due to the fault of the User as
described in this Agreement.
    </div>
    <div>
      6. Termination of the Agreement by the User may be done by confirming this option by
clicking the specified button in the User's account on the Platform. Upon termination of
the Agreement, the User's account becomes inaccessible, and the User's data is stored to
such an extent and for such a period of time as required by generally applicable laws.
    </div>
    <div>
      7. The contract shall also be terminated without notice in the event of loss of legal capacity
by the Customer. The manner of transfer or disposition of funds in such case will be in
accordance with generally applicable laws.
    </div>
    <div>
    8. The Agreement may also be terminated without notice in the event of:
1) gross (longer than 3 billing periods) or repeated delays in payment of commission by
the Platform,
2) violation of the provisions regarding the protection of personal data;
3) violation of provisions regarding the rights of third parties, in particular personal
rights or intellectual property rights and consumer rights;
4) 5) reasonable suspicion that a crime has been committed,
when requested by the Third Party Provider.
    </div>
  </div>
    </div>
    <div id="§6" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§ 6 RESPONSIBILITY OF THE PARTIES</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The User is responsible for the actions taken on and with the use of the platform,
including within the functionality of the User's account. It is presumed that it is the User
who is responsible for the actions that are taken using his account.
    </div>
    <div>
      2. The User, being aware of the risks associated with the crypto asset industry and the use
of the Platform, is responsible for his/her own actions that will lead to the loss of funds.
The Platform is not responsible for and does not guarantee the preservation of a certain
value by the cryptocurrencies held by the Client in the Wallet.
    </div>
    <div>
      3. The Platform shall be liable to Users for the provision of services only to the extent
permitted by generally applicable law. The Platform shall not be liable for the provision
of services and for the acts and omissions of Third Party Providers towards Users. The
Platform also does not act as an intermediary or arbitrator in relations between Users and
4. Third Party Providers. The Platform is also not responsible for providing information or
documents regarding the activities of such persons, nor for negotiating contracts with
such entities. The User should familiarize themselves with the offer of Third Party
Providers on the websites or applications of such entities. The Platform is not responsible
for the timeliness of information about offers or agreements with such entities provided
on the Platform.
    </div>
    <div>
      4. The Platform is also not responsible for:
1) 2) 3) User's actions contrary to the law and the Regulations,
Sharing data, including account access data with third parties,
Deletion or loss of account data caused by the User himself or by third parties,
4) technical problems related to the deposit or withdrawal of funds from the Wallet,
and concerning networks, devices, applications or programs independent of the
Platform,
5) entering incorrect or mistaken data when using the account, the Wallet, or making
transactions,
6) the length of the various activities and operations performed using the account or
Wallet,
7) the impossibility of carrying out transactions and taking other actions within a
certain period of time, if it is related to technical limitations of carrying out such
transactions or actions,
8) technical limitations related to the operation of the User's devices.
    </div>
  </div>
    </div>
    <div id="§7" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§ 7 INTELLECTUAL PROPERTY</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The Platform may give permission for the User to use the logo and name of the Platform
to inform about its activities and services. In order to obtain permission, the User will
send a draft of the information planned to be published or distributed, the place where it
will be available. The platform will make a decision as soon as possible at its sole
discretion.
    </div>
    <div>
      2. The Platform, all content, logos and other materials comprising the Platform are the
intellectual property of the Platform and other third parties. The User is not entitled to
copy or distribute this content to any extent and in any way , without obtaining the
appropriate license or transfer of rights. This applies to the Platform as a whole, but also
to each individual element of the Platform, which may be considered a copyrighted work
or a patent, trademark, industrial design protected by relevant laws.
    </div>
  </div>
    </div>
    <div id="§8" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§ 8 COMPLAINTS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. If the User becomes reasonably and documented convinced that the Platform violates the
Terms of Use or the law, he/she may file a complaint by directing it to the following
email address: [...]. The User is obliged to indicate contact details, login, Wallet number,
description of the problem and the proposed way to solve it.
    </div>
    <div>
      2. Complaints may also be filed in other cases provided by law.
    </div>
    <div>
      3. Complaints will be considered within 30 days from the date of notification. If the
information presented by the User is not sufficient to consider the claim, the Platform will
notify the User to supplement the claim. If the information is not supplemented
promptly , the application will be left without consideration.
    </div>
    <div>
      4. Information on how the complaint was handled will be sent to the User at the e-mail
      address or through the account on the Platform.
    </div>
  </div>
    </div>
    <div id="§9" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§ 9 CHANGES TO THE REGULATIONS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    The current Regulations are published on the Website. The Platform reserves the right to
amend the Regulations. It is considered that from the moment the new/amended version of
the Regulations is published on the Platform, the new/amended version is binding on the
Users. In the event that the User does not accept the new/changed version of the
Regulations, he may terminate the Agreement on the usual terms provided for in these
Regulations. Until the termination of the agreement, the User is bound by the new/amended
version of the Regulations.
    </div>
  </div>
    </div>
    <div id="§10" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium">§ 10 FINAL PROVISIONS</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
      1. The Regulations supersede all previous arrangements between the Platform and the User
      in the provision of services described in the Regulations.
    </div>
    <div>
      2. In matters not covered by the Regulations, the laws of Saint Vincent and the Grenadines
      shall apply .
    </div>
    <div>
      3. The provided personal data of the Platform and the User are processed in accordance
      with applicable law.
    </div>
    <div>
      4. The Platform shall provide [...] the User's identification and contact data necessary for the
Providers to provide their services. The legal basis for the provision of data is the
necessity for the performance of the contract.
    </div>
    <div>
      5. Any disputes arising out of or in connection with these Regulations shall be settled by a
      common court of competent jurisdiction applicable to the Platform.
    </div>
    <div>
      6. Whenever it is permitted by generally applicable law, disputes between the Platform and
the User will be resolved by binding arbitration. This applies to all matters that may arise
against the background of the Regulations.
    </div>
  </div>
    </div>

    </section>
  );
};

export default TCComponent;
