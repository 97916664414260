import { FunctionComponent } from "react";
import Topbar from "../components/Topbar";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import OurSolutions from "../components/OurSolutions";
import FrameComponent from "../components/FrameComponent";
import ProductLaunch from "../components/ProductLaunch";
import TeamContainer from "../components/TeamContainer";
import PartnerWithUs from "../components/PartnerWithUs";
import TokenSale from "../components/TokenSale";
import Footer from "../components/Footer";
import Roadmap1 from "../components/Roadmap1";
import Roadmap from "../components/Roadmap";
import TCComponent from "../components/TCComponent";

const TC: FunctionComponent = () => {
  return (
    <div className="w-full max-w-[1440px] mx-auto">
      <div className="w-full relative bg-white overflow-hidden flex flex-col items-start justify-start leading-[normal] tracking-[normal] text-center text-45xl text-font-color-dark-800 font-palanquin">
        <Topbar />
        <TCComponent />
        <Footer />
      </div>
    </div>
  );
};

export default TC;
