import { FunctionComponent } from "react";

const PPComponent: FunctionComponent = () => {
  return (
    <section className="self-stretch flex flex-col gap-6 justify-center py-16 px-20 mq1200:py-6 mq1200:px-8 mq450:px-4 mq450:py-6 box-border max-w-full text-left text-45xl text-font-color-dark-800 font-palanquin mq450:box-border mq800:gap-[24px] mq800:py-[83px] mq800:px-10 mq800:box-border"> 
    <div className="flex w-full justify-center text-[20px] font-medium uppercase">Privacy Policy Pays Solutions</div>
    <div id="Who Are We" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Who Are We?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    The administrator of your personal data is Pays Solutions. Pays Solutions is not the
administrator of third-party data; this role is handled by Solutions Technology. Please
include the registration number where the company is listed (the equivalent of Poland's
KRS): Corporate Tax in the United Arab Emirates : 104537502700001
    </div>
  </div>
    </div>
    <div id="What Data Do We Collect" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">What Data Do We Collect?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We collect only email addresses. We provide services for a non-custodial crypto asset wallet
solution, enabling the storage and transfer of crypto asset units to other wallets.
    </div>
  </div>
    </div>
    <div id="Transparency" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Transparency</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Our goal is to ensure full transparency and provide all information about our data
processing activities and the scope of personal data processed concerning users. We strive
to provide this information in a simple manner while maintaining all essential details.
    </div>
  </div>
    </div>
    <div id="What Are Personal Data?" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">What Are Personal Data?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Personal data are any information that can directly or indirectly identify a specific
individual. This may include identifiers such as name, email address, identification number,
location data, or an online identifier. It may also include information about physical,
physiological, genetic, mental, economic, cultural, or social identity factors of an individual.
    </div>
  </div>
    </div>
    <div id="Why Do We Process Your Personal Data?" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Why Do We Process Your Personal Data?</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We process users' personal data to ensure the safe and efficient delivery of services. This
includes, but is not limited to:
- Managing user accounts (non-custodial wallets, no account management)
- Compliance with legal and regulatory requirements
- Communicating with users regarding services
- Ensuring the security, protection, and integrity of our platform
- Providing user support, improving services, sending marketing information, preventing
fraud, and coordinating transactions.
    </div>
  </div>
    </div>
    <div id="Principles of Data Protection" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Principles of Data Protection</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    1. Lawfulness, Fairness, and Transparency:
We process personal data in compliance with applicable laws, maintaining the highest level
of user privacy. Our processing ensures no discrimination or arbitrary use of your data.<br />
2. Purpose Limitation:
We only process personal data for the purposes for which they were collected and for which
you have been informed. We do not create internal databases for our purposes or perform
unauthorized processing operations.<br />
3. Data Minimization:
We analyze and process only the necessary data required for managing the cryptocurrency
wallet or account creation. This includes your name, email address, company, and wallet
address. We use a "Single Sign-On" mechanism and only receive an authentication token, not
your login credentials. We do not collect data preemptively or for future use.<br />
4. Accuracy: We ensure that your personal data is accurate and up-to-date. You can update
your information if it becomes outdated or incorrect.<br />
5. Storage Limitation: Personal data are stored only as long as necessary, depending on
account ownership and legal requirements.
    </div>
  </div>
    </div>
    <div id="Your Rights" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Your Rights</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    1. Right to Be Informed:
The administrator must inform you about their identity, how they process your data, the
purposes and legal bases, your rights, and other necessary details. If the purpose of
processing changes, we will inform you again.<br />
2. Right to Withdraw Consent:
You may withdraw your consent to data processing at any time without negative
consequences. This does not affect the lawfulness of processing based on consent before its
withdrawal.<br />
3. Right of Access:
You have the right to access your data, including obtaining a copy in a machine-readable
format.<br />
4. Right to Rectification:
If your data is incorrect or outdated, we will update it upon your request.<br />
5. Right to Erasure:
You may request the deletion of your data, though this is not absolute. Data processed on
consent for marketing purposes, for example, will be deleted upon request. However, legal
obligations may require us to retain some data.<br />
6. Right to Restriction of Processing:
You may request restrictions on processing under certain conditions, such as disputing the
accuracy or lawfulness of the data processing.<br />
7. Right to Object:
You can object to processing based on legitimate interests.<br />
8. Right to Data Portability:
You can request your data in a structured, machine-readable format for transfer to another
administrator.<br />
9. Right to Lodge a Complaint:
If you believe your data is being processed unlawfully, you can file a complaint with a
supervisory authority.
    </div>
  </div>
    </div>
    <div id="How to Exercise Your Rights" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">How to Exercise Your Rights</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Contact us via:
- Written correspondence to our headquarters: Unit IH-00-VZ-01-FL-97 Level 1
    </div>
  </div>
    </div>
    <div id="Innovation Hub, DIFC, Dubai, Dubai" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Innovation Hub, DIFC, Dubai, Dubai</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Email: contact@pays.solutions We will respond promptly, within a month of receiving your
request. For complex or multiple requests, this period may extend by two months, and you
will be informed accordingly. Requests are generally free, but
    </div>
  </div>
    </div>
    <div id="Legal Bases for Processing" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Legal Bases for Processing</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    1.Consent:
Personal data may be processed with your informed consent. You can withdraw it anytime
without adverse effects. Consent applies to specific purposes like marketing or newsletters.<br />
2. Contract: Personal data are processed to perform a contract, such as account creation.<br />
3. Legal Obligation: Data may be processed to comply with legal requirements, especially in
jurisdictions outside the EU.<br />
4. Legitimate Interest: Data may be processed for internal purposes, such as fraud
prevention or claims defense. You may object to such processing.
    </div>
  </div>
    </div>
    <div id="Privacy by Design and Default" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Privacy by Design and Default</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We implement technical and organizational measures to ensure data security,
confidentiality, and integrity. Default settings prioritize maximum privacy.
    </div>
  </div>
    </div>
    <div id="Children's Privacy" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Children's Privacy</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Only adults can create accounts. If we discover minors' data is processed due to
misrepresentation, we reserve the right to delete the account and associated data.
    </div>
  </div>
    </div>
    <div id="Data Protection Officer" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Data Protection Officer</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We have appointed a Data Protection Officer (DPO) to ensure compliance with data
protection laws. Contact: dpo@pays.solutions
    </div>
  </div>
    </div>
    <div id="Data Retention Period" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Data Retention Period</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Data are processed as required for the purposes mentioned above and retained as mandated
by law.
    </div>
  </div>
    </div>
    <div id="Data Recipients" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Data Recipients</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Data may be shared with authorized entities, courts, law firms, IT service providers, and
other contracted entities.
    </div>
  </div>
    </div>
    <div id="Data Processing Location" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Data Processing Location</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Data are processed in the UAE and the European Economic Area. If processing occurs
elsewhere, safety measures, including contractual clauses, will be implemented.
    </div>
  </div>
    </div>
    <div id="Automated Decision-Making" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Automated Decision-Making</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    Personal data will not be subject to automated decision-making or profiling.
    </div>
  </div>
    </div>
    <div id="Cookies" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Cookies</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We use cookies for:<br />
- Maintaining user sessions<br />
- Statistics tracking<br />
- Simplifying user experience<br />
Cookies can be session-based (temporary) or persistent (stored for longer periods). Users
can customize their browser cookie settings.
    </div>
  </div>
    </div>
    <div id="Analytics" className="flex flex-col gap-4">
  <div className="flex w-full justify-center text-[20px] font-medium uppercase">Analytics</div>
  <div className="flex flex-col gap-4 text-[16px] font-normal">
    <div>
    We use Google Analytics to collect anonymized data such as unique visits, location, browser
type, and visit duration.
    </div>
    <div>
    <b>Note: </b>This policy may change, and updated versions will be posted on our website.
    </div>
  </div>
    </div>

    </section>
  );
};

export default PPComponent;
